<template>
  <el-dialog title="预览"
             :close-on-click-modal="false"
             :visible.sync="previewDialog"
             width="40%"
             append-to-body>
    <el-tabs type="border-card">
      <el-tab-pane>
        <span slot="label"><i class="iconfont icon-biaodanmoshi" style="margin-right: 2px"></i>表</span>
        <div v-for="table in previewData.DBData" :key="table.id">
          <el-tag>{{ table.tableIndex }}</el-tag>
          <el-divider class="tableDivider"></el-divider>
          <el-table
              :data="table.tableData"
              height="250"
              border
              style="width: 100%">
            <el-table-column
                v-for="col in table.tableContent" :key="col.id"
                :prop="col.prop" :label="col.label"
                width="180">
            </el-table-column>
          </el-table>
        </div>
      </el-tab-pane>
      <el-tab-pane><span slot="label"><i class="iconfont icon-txtwenjian" style="margin-right: 2px"></i>txt</span>
      </el-tab-pane>
      <el-tab-pane><span slot="label"><i class="iconfont icon-Excel" style="margin-right: 2px"></i>excel</span>
      </el-tab-pane>
      <el-tab-pane><span slot="label"><i class="iconfont icon-Word" style="margin-right: 2px"></i>word</span>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" class="dialog-footer">
       <el-button @click="previewDialog = false">取 消</el-button>
       <el-button type="primary" @click="previewDialog = false">确 定</el-button>
      </span>
  </el-dialog>
</template>

<script>
import axios from "axios";

export default {
  name: "preview",
  props: ["allTaskData"],
  data() {
    return {
      previewDialog: false,
      previewData: {
        DBData: [],
        TxtData: [],
        ExcelData: [],
        WordData: [],
      },
    }
  },
  created() {
    this.previewData.DBData = []
    this.previewData.TxtData = []
    this.previewData.ExcelData = []
    this.previewData.WordData = []
    let uid = this.currOperator
    for (let i = 0; i < this.allTaskData.taskData.length; i++) {
      let DBData = {
        id: 0,
        tableData: [],
        tableContent: [],
        tableIndex: ''
      }
      let name = this.allTaskData.taskData[i].name;
      let tableDBType = this.allTaskData.accessSource
      if (Object.keys(this.allTaskData.taskData[i].reflectData).length !== 0) {
        for (let j = 0; j < this.allTaskData.taskData[i].reflectData.DB.length; j++) {
          let tableDB = this.allTaskData.taskData[i].reflectData.DB[j].DBName
          let tables = []
          for (let k = 0; k < this.allTaskData.taskData[i].reflectData.DB[j].table.length; k++) {
            if (!tables.includes(this.allTaskData.taskData[i].reflectData.DB[j].table[k].tableName)) {
              tables.push(this.allTaskData.taskData[i].reflectData.DB[j].table[k].tableName)
              let data = {
                tableDBType: tableDBType,
                tableDB: tableDB,
                uid: uid,
                tableName: this.allTaskData.taskData[i].reflectData.DB[j].table[k].tableName
              }
              DBData.tableIndex = data.tableDB + "." + data.tableName
              this.postRequest("/addDataAccessTask/tableData", data).then(response => {

                let tableContent = JSON.parse(JSON.stringify(response.data[0]))
                let tableData = JSON.parse(JSON.stringify(response.data[1]));
                for (let m = 0; m < tableContent.length; m++) {
                  let temp = {
                    id: m,
                    label: tableContent[m],
                    prop: tableContent[m]
                  }
                  DBData.tableContent.push(temp)
                }
                DBData.tableData = tableData
                DBData.id++
                this.previewData.DBData.push(DBData)
              }).then(error => {
                // console.log(error)
              })
            }
          }
        }
      }
    }
  },
  methods: {
    open() {
      this.previewDialog = true
    },
    close() {
      this.previewDialog = false
    },
  },
}
</script>

<style scoped>

</style>
